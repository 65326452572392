@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons+Outlined');
:root {
  --name-text: #86ddff;
  --dark-blue: #002b8f;
  --mid-blue: #3ba0ff;
}
html, body {
  margin-bottom: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

.nav-container {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  z-index: 2;
  width: 100%;
}
/* ***********************
******** NAVBAR **********
************************** */
.navbar a.selected-text {
  color: var(--name-text);
  font-size: 30px;
  font-weight: bold;
  
 }
.navbar {
  position: absolute;
  z-index: 2;
  background-color: rgba(100, 100, 100, 0.2);
  height:10vh;
  width: 100%;
  padding:   0 10%;
}

@media screen and (max-width: 768px ) {
  .navbar {
    background-color: rgba(75, 76, 76, 0.384);
    bottom: 0;
    height: 7vh;
      }
}

.navbar > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}


/* HERO SECTION */
.hero-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.image-container{
  position: relative;
  background-image: url(components/img/hero-img.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  margin-top: 0vh;
  animation: scale 3s;
}
@keyframes scale {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  z-index: 1;
}
.hero-text {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  position: absolute;
  top: 35%;
  left: 10%;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  z-index: 2;
}
.firstWord {
  color: var(--name-text);
}
.resume-cta {
  border: 2px solid var(--name-text);
  width: 175px;
  padding: 30px;
  margin-top: 20px;
}
a.resume-cta {
  text-decoration: none;
}
.resume-text, .form-text > h2 {
  color: #fff;
}

/*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
                PROJECTS SECTION 
*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */
.no-animation {
  animation: none;
  
}
/* used by Projects and Contact */
.static-bg {
  overflow: auto;
  position: relative;
  height: 100vh;  
}
.projects-header {
  margin-top: 13vh;
  flex: 0 0 100%;
  text-align: center;
  color: #fff;
  margin-bottom: -4vh;
}
@media screen and (max-width: 768px ) {
  .projects-header {
    margin-top: 2vh;
  }
}
.projectDetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;  
}
@media screen and (max-width: 768px ) {
  .projectDetail {
    margin-top: 20%;
  }
}
/* CONTACT COMPONENT */
.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}
#form{
  margin-top: 100px;
}
#button {
  justify-content: space-evenly;
}
.form-group {
  position: relative;
}

/* TOAST */
#toasty {
  top: 15em;
  right: 29em;
  z-index: 2;
  width: inherit;
}
.rounded {
  background-color: #007AFF;
  width: 20px;
  height: 20px;
  
}
/* FORM VALIDATION TOOTIPS */
.valid-tooltip {
  right: 0;
  left: inherit;
}
.invalid-tooltip {
  right: 0;
  left: inherit;
}
/* TOAST STYLING */
div.fade.toast.show {
  position: fixed;
  top: 28%;
  left: 35%;
  max-width: 500px;
  background-color: #fff;

}
@media screen and (max-width: 768px ) {
  div.fade.toast.show {
    top: 20%;
    left: 0;
  }
}
.form-label {
  color: rgb(175, 174, 172);
  margin: auto;
}
#form-instruction-text {
  color: rgb(175, 174, 172);

}